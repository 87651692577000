@import url('https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100..900;1,100..900&display=swap');


@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: "Jost", sans-serif;
  font-optical-sizing: auto;

}